import { IUser } from '../reducers/user';
import { State } from '../state';
import { createSelector } from 'reselect';

export function getUserInfo(state: State) {
  return state.user.user;
}

export const getClipExpire = createSelector(
  getUserInfo,
  (user: IUser | null) =>
    user!.clipExpire && new Date(parseInt(user!.clipExpire) * 1000),
);

export const getExpire = createSelector(
  getUserInfo,
  (user: IUser | null) =>
    user!.payjpBenefit &&
    user!.expire &&
    new Date(parseInt(user!.expire) * 1000),
);

export function getRegisteredStatus(state: State) {
  return state.user.registered;
}
