import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PlanItem } from '../../../components';
import { plans } from '../../../utils/plans';
import payjpPlanId, { convertPlan, IPlan } from '../../../utils/payjpPlanId';
import {
  updateOneDaySubscription,
  updateSubscription,
} from '../../../store/actions/user';
import { getExpire, getUserInfo } from '../../../store/selectors/user';
import { Features } from '../../../store/reducers/user';

const Plan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserInfo);

  const currentPlans = useMemo(() => {
    return payjpPlanId[convertPlan(user?.payjpPlanId || '0')];
  }, [user?.payjpPlanId]);
  const [selectedPlans, setSelectedPlans] = useState(currentPlans);

  const currentOneDayPlans = useMemo(() => {
    return payjpPlanId[convertPlan(user?.payjpBenefit || '0')];
  }, [user?.payjpBenefit]);
  const [oneDaySelectedPlans, setOneDaySelectedPlans] = useState(
    currentOneDayPlans,
  );

  const [step, setStep] = useState(0);
  const [isOneDayPlan, setIsOneDayPlan] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [completeMessage, setMessage] = useState('');

  const date = useSelector(getExpire);

  const PlansPrice = (plan: IPlan) => {
    let PlansPrice = 0;
    (Object.keys(plan) as Features[]).forEach((item) => {
      if (plan[item]) {
        PlansPrice += isOneDayPlan
          ? plans[item].oneDayPrice || 0
          : plans[item].price || 0;
      }
    });
    return PlansPrice;
  };

  const buttonPressed = (id: string, isChecked: boolean) => {
    if (id === 'englishTranslate' && !isChecked === true) {
      isOneDayPlan
        ? setOneDaySelectedPlans({
            ...oneDaySelectedPlans,
            [id]: !isChecked,
            allLanguageTranslate: false,
          })
        : setSelectedPlans({
            ...selectedPlans,
            [id]: !isChecked,
            allLanguageTranslate: false,
          });
    } else if (id === 'allLanguageTranslate' && !isChecked === true) {
      isOneDayPlan
        ? setOneDaySelectedPlans({
            ...oneDaySelectedPlans,
            [id]: !isChecked,
            englishTranslate: false,
          })
        : setSelectedPlans({
            ...selectedPlans,
            [id]: !isChecked,
            englishTranslate: false,
          });
    } else if (id !== 'speechToText') {
      isOneDayPlan
        ? setOneDaySelectedPlans({
            ...oneDaySelectedPlans,
            [id]: !isChecked,
          })
        : setSelectedPlans({
            ...selectedPlans,
            [id]: !isChecked,
          });
    }
  };

  const onSubmit = () => {
    const selectedPlansId = Object.keys(payjpPlanId).find(
      (key) =>
        JSON.stringify(payjpPlanId[key]) ===
        JSON.stringify(isOneDayPlan ? oneDaySelectedPlans : selectedPlans),
    );
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setLoading(true);
      dispatch(
        isOneDayPlan
          ? updateOneDaySubscription(
              { planId: selectedPlansId! },
              (message?: string) => {
                if (!message) {
                  toast.success(
                    'プランが変更され、新しいプランの機能がご利用できる状態になりました。',
                  );
                  history.push('/');
                } else {
                  setMessage(message);
                  setStep(2);
                }
              },
            )
          : updateSubscription(
              { planId: selectedPlansId! },
              (message?: string) => {
                if (!message) {
                  toast.success(
                    'プランが変更され、新しいプランの機能がご利用できる状態になりました。',
                  );
                  history.push('/');
                } else {
                  setMessage(message);
                  setStep(2);
                }
              },
            ),
      );
    }
  };

  const onBack = useCallback(() => {
    history.push('/');
  }, [history]);

  const changeToOneDay = () => {
    setIsOneDayPlan(true);
  };
  const changeToMonthly = () => {
    setIsOneDayPlan(false);
  };

  const toPaymentMethodChange = useCallback(() => {
    history.push({
      pathname: '/settings',
      state: { id: 'payment' },
    });
  }, [history]);

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <div className="plan-list">
            {user!.incomplete ? (
              <>
                <div>
                  決済手続きを完了していません。お支払い方法をご更新ください。
                </div>
                <p className="text-link" onClick={toPaymentMethodChange}>
                  お支払い方法の変更へ
                </p>
              </>
            ) : (
              user!.payjpBenefit && (
                <>
                  {date && (
                    <div className="benefit-text">{`プラン利用期間「${date.getFullYear()}年${
                      date.getMonth() + 1
                    }月${date.getDate()}日 ${date.getHours()}時${date.getMinutes()}分」まで`}</div>
                  )}
                </>
              )
            )}
            <p className="text-secondary">
              <u>解約をご希望の方は無料プランに変更してください。</u>
            </p>
            <div className="selector">
              <button
                type="button"
                className={
                  isOneDayPlan ? 'selector-text selected-text' : 'selector-text'
                }
                onClick={changeToOneDay}>
                1日だけ使う
              </button>
              <button
                type="button"
                className={
                  !isOneDayPlan
                    ? 'selector-text selected-text'
                    : 'selector-text'
                }
                onClick={changeToMonthly}>
                １ヶ月更新で使う
              </button>
            </div>
            <p className="top-text">複数選択が可能です</p>
            {Object.values(plans).map((el) => (
              <PlanItem
                key={el.id}
                data={el}
                isOneDayPlan={isOneDayPlan}
                isChecked={
                  isOneDayPlan
                    ? oneDaySelectedPlans[el.id]
                    : selectedPlans[el.id]
                }
                buttonPressed={buttonPressed}
                isDisabled={el.id === 'speechToText'}>
                <p className="mb-0 font-sm">{el.description}</p>
              </PlanItem>
            ))}
            <div className="plan-bottom">
              <span className="bottom-left-text">
                ※英語翻訳と100ヶ国語を同時に選択する事はできません。
                <br />
                変更時に適用前プランは即時破棄されます。
              </span>
              <span className="bottom-right-text">
                合計：
                {PlansPrice(isOneDayPlan ? oneDaySelectedPlans : selectedPlans)}
                ¥
              </span>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="plan-update-info font-weight-bold">
            {!isOneDayPlan && (
              <>
                <ul className="list-design">
                  {Object.values(plans).map(
                    (item) =>
                      currentPlans[item.id] && (
                        <li key={item.id}>
                          {`${item.name} ${item.price ? item.price : '0'}¥/月`}
                        </li>
                      ),
                  )}
                </ul>
                <span>---------------------</span>
                <span>{`${PlansPrice(currentPlans)}¥/月`}</span>
                <span>↓</span>
              </>
            )}
            <ul className="list-design">
              {Object.values(plans).map(
                (item) =>
                  (!isOneDayPlan
                    ? selectedPlans[item.id]
                    : oneDaySelectedPlans[item.id]) && (
                    <li key={item.id}>
                      {!isOneDayPlan
                        ? `${item.name} ${
                            item.price && item.oneDayPrice
                              ? isOneDayPlan
                                ? item.oneDayPrice + '¥'
                                : item.price + '¥/月'
                              : '0¥'
                          }`
                        : oneDaySelectedPlans[item.id] &&
                          `${item.name} ${
                            item.price && item.oneDayPrice
                              ? isOneDayPlan
                                ? item.oneDayPrice + '¥'
                                : item.price + '¥/月'
                              : '0¥'
                          }`}
                    </li>
                  ),
              )}
            </ul>
            <span>---------------------</span>
            <span>{`${PlansPrice(
              isOneDayPlan ? oneDaySelectedPlans : selectedPlans,
            )}¥${isOneDayPlan ? '' : '/月'}`}</span>
            <p className="warning-text mb-0 font-sm">
              プラン変更すると終了期間に至らずとも契約中のプランは解約され、新しいプランの契約が即座に始まります。
            </p>
          </div>
        );
      default:
        return (
          <>
            <p>{completeMessage}</p>
            <p className="text-link" onClick={toPaymentMethodChange}>
              お支払い方法の変更へ
            </p>
          </>
        );
    }
  };

  const renderStepButtonLabel = () => {
    switch (step) {
      case 0:
        return 'プランを変更する';
      case 1:
        return '保存';
      default:
        return '戻る';
    }
  };

  return (
    <>
      <h4 className="page-title">プランの変更</h4>
      {renderSteps()}
      <div className="button-container">
        <button type="button" className="back-button" onClick={onBack}>
          キャンセル
        </button>
        <button
          type="button"
          className="next-button"
          onClick={step < 2 ? onSubmit : onBack}
          disabled={(!!user!.incomplete || isLoading) && step < 2}>
          {renderStepButtonLabel()}
        </button>
      </div>
    </>
  );
};

export default Plan;
