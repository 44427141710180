import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from '../../components';

import { forgotPassword } from '../../store/actions/user';

import { isValidEmail } from '../../utils/validation';

import './styles.scss';

const Forgot = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    if (!isValidEmail(email)) {
      setError('メールアドレス形式ではありません');
      return false;
    }

    setError('');
    return true;
  }, [email]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [isSubmitted, email, validate]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(
        forgotPassword({ email }, () => {
          setIsSubmitted(false);
          setEmail('');
        }),
      );
    }
  };

  return (
    <Layout withHeader>
      <form className="forgot-password-page sm-content" onSubmit={onSubmit}>
        <p className="helper-text">
          登録したアカウントのメールアドレスに
          <br />
          認証用のURLを送信します
        </p>
        <div className="form-group">
          {error && <p className="invalid-text">{error}</p>}
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          メール送信
        </button>
      </form>
    </Layout>
  );
};

export default Forgot;
