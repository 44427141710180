import { Features } from '../store/reducers/user';

export const plans: Record<
  Features,
  {
    id: Features;
    name: string;
    price?: number;
    oneDayPrice?: number;
    value: number;
    description: string;
  }
> = {
  speechToText: {
    id: 'speechToText',
    name: '声を届ける',
    value: 0,
    description: '入力された音声をスピーカーから出力する機能です。',
  },
  voiceMemo: {
    id: 'voiceMemo',
    name: '音声メモ',
    price: 580,
    oneDayPrice: 50,
    value: 4,
    description: '入力された音声を文字起こしして、メモにする機能です。',
  },
  englishTranslate: {
    id: 'englishTranslate',
    name: '英語翻訳',
    price: 580,
    oneDayPrice: 50,
    value: 2,
    description:
      '日本語と英語間を翻訳します。入力された音声を文字で翻訳し、読み上げます。',
  },
  allLanguageTranslate: {
    id: 'allLanguageTranslate',
    name: '100ヶ国語翻訳',
    price: 980,
    oneDayPrice: 100,
    value: 3,
    description:
      '100カ国語間を翻訳します。入力された音声を文字で翻訳し、読み上げます。',
  },
  // meetingMemo: {
  //   id: 'meetingMemo',
  //   name: '議事録',
  //   price: 980,
  //   oneDayPrice: 100,
  //   value: 4,
  //   description: '複数のスマートマスクを使って、会議の音声をメモする機能です。',
  // },
};
