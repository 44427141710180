export const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.41"
    height="12"
    viewBox="0 0 7.41 12">
    <path
      id="Path_182"
      data-name="Path 182"
      d="M8.59,16.59,13.17,12,8.59,7.41,10,6l6,6-6,6Z"
      transform="translate(-8.59 -6)"
      fill="currentColor"
    />
  </svg>
);
