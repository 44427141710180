import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ArrowRightIcon } from '../../components/Icon';

import { checkRegistered, logout } from '../../store/actions/user';
import {
  getClipExpire,
  getExpire,
  getRegisteredStatus,
  getUserInfo,
} from '../../store/selectors/user';
import { getCouponDoNotShowState } from '../../utils/localStorageUtils';
import payjpPlanId, { convertPlan } from '../../utils/payjpPlanId';

import { plans } from '../../utils/plans';

import './styles.scss';

const padNumber = (num: number) => {
  return num.toString().padStart(2, '0');
};

const formatDate = (date: Date) => {
  return `${date.getFullYear()}:${padNumber(date.getMonth() + 1)}:${padNumber(
    date.getDate(),
  )} ${padNumber(date.getHours())}:${padNumber(date.getMinutes())}`;
};

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserInfo);
  const date = useSelector(getExpire);
  const clipDate = useSelector(getClipExpire);
  const registered = useSelector(getRegisteredStatus);

  const currentPlans = useMemo(() => {
    return user?.payjpPlanId && user?.payjpPlanId !== '0'
      ? payjpPlanId[convertPlan(user?.payjpPlanId)]
      : null;
  }, [user?.payjpPlanId]);

  const currentOneDayPlans = useMemo(() => {
    return user?.payjpBenefit && user?.payjpBenefit !== '0'
      ? payjpPlanId[convertPlan(user?.payjpBenefit)]
      : null;
  }, [user?.payjpBenefit]);

  useEffect(() => {
    if (!user!.isAdmin && registered === undefined) {
      const doNotShow = getCouponDoNotShowState();
      if (!doNotShow) {
        // fetch registered status
        dispatch(
          checkRegistered((reg) => {
            if (!reg) {
              history.push({
                pathname: '/settings',
                state: { id: 'coupon' },
              });
            }
          }),
        );
      }
    }
  }, [dispatch, user, history, registered]);

  const onPressItem = (id: string) => {
    history.push({
      pathname: '/settings',
      state: { id },
    });
  };

  const onLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <div className="home-page sm-content">
      <h4 className="page-title">アカウント</h4>

      <div className="user-info">
        <h4>{user!.displayName}</h4>
        <span>{user!.email}</span>
        {!currentPlans && !currentOneDayPlans && (
          <div className="row mt-2">
            <div className="col-auto pr-0">C-FACE:</div>
            <div className="col-auto">
              <span>
                <small>【声を届ける】【0円】【有効期限はありません】</small>
              </span>
            </div>
          </div>
        )}
        {(currentPlans || currentOneDayPlans) && (
          <div className="row mt-2">
            <div className="col-auto pr-0">C-FACE:</div>
            <div className="col-auto">
              {Object.values(plans).map((plan) =>
                (currentPlans || currentOneDayPlans)![plan.id] && plan.price ? (
                  <span key={plan.id}>
                    <small>{`【${plan.name}】【${plan.price}円】${
                      date ? `【${formatDate(date)}】` : ''
                    }`}</small>
                    <br />
                  </span>
                ) : null,
              )}
            </div>
          </div>
        )}
        {user!.clipDevice && (
          <div className="row mt-2">
            <div className="col-auto pr-0">clip EAR:</div>
            <div className="col-auto">
              <small>{`MAKUAKEプラン${
                clipDate
                  ? `【有効期限（${formatDate(clipDate)}）】`
                  : '【有効期限はありません】'
              }`}</small>
            </div>
          </div>
        )}
        {user!.incomplete && (
          <>
            <br />
            <div>
              決済手続きを完了していません。お支払い方法をご更新ください。
              <i
                className="cancel-account-link"
                onClick={() => onPressItem('payment')}>
                お支払い方法の変更へ
              </i>
            </div>
          </>
        )}
        {registered !== undefined && !registered && (
          <div className="mt-2">製品番号の登録を行ってください</div>
        )}
      </div>

      <ul className="settings-list">
        <li onClick={() => onPressItem('displayName')}>
          <span>表示名の変更</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('name')}>
          <span>氏名の変更</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('email')}>
          <span>メールアドレスの変更</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('password')}>
          <span>パスワードの変更</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('payment')}>
          <span>お支払い方法の変更</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('coupon')}>
          <span>製品番号の登録</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('plan')}>
          <span>契約プランの変更・解約</span>
          <ArrowRightIcon />
        </li>
        <li onClick={() => onPressItem('download')}>
          <span>各種ダウンロード</span>
          <ArrowRightIcon />
        </li>
      </ul>

      <button
        type="button"
        className="btn btn-outline-primary btn-block"
        onClick={onLogout}>
        サインアウト
      </button>
      <p
        className="cancel-account-link text-center"
        onClick={() => onPressItem('cancelAccount')}>
        退会についてはこちら
      </p>
    </div>
  );
};

export default Home;
