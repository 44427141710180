import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { register } from '../../store/actions/user';
import { getEnvironment } from '../../store/selectors/app';

import { isValidEmail, isValidPassword } from '../../utils/validation';

import './styles.scss';

interface IError {
  email?: string;
  password?: string;
  confirm?: string;
  name?: string;
  displayName?: string;
}

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
    confirm: '',
    name: '',
    displayName: '',
  });
  const [errors, setErrors] = useState<IError>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const appEnv = useSelector(getEnvironment);

  const validate = useCallback(() => {
    const e: IError = {};
    if (!isValidEmail(inputValues.email)) {
      e.email = 'メールアドレス形式ではありません';
    }
    if (!isValidPassword(inputValues.password)) {
      e.password = '6文字以上、40文字以下で入力してください';
    }
    if (inputValues.password !== inputValues.confirm) {
      e.confirm = 'パスワードが一致しません';
    }
    if (!inputValues.name) {
      e.name = '使用不可能な文字が含まれています';
    }
    if (!inputValues.displayName) {
      e.displayName = '使用不可能な文字が含まれています';
    }

    setErrors(e);
    return Object.values(e).length === 0;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, inputValues]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(register(inputValues, () => history.push('/login')));
    }
  };

  return (
    <form className="signup-page sm-content" onSubmit={onSubmit}>
      <h4 className="page-title">アカウントの作成</h4>
      <div className="form-group">
        {errors.email && <p className="invalid-text">{errors.email}</p>}
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder="メールアドレス"
          value={inputValues.email}
          onChange={onInputChange}
        />
      </div>
      <div className="form-group">
        {errors.password && <p className="invalid-text">{errors.password}</p>}
        <input
          type="password"
          className="form-control"
          name="password"
          placeholder="パスワード（6文字以上、40文字以下）"
          value={inputValues.password}
          onChange={onInputChange}
        />
      </div>
      <div className="form-group">
        {errors.confirm && <p className="invalid-text">{errors.confirm}</p>}
        <input
          type="password"
          className="form-control"
          name="confirm"
          placeholder="パスワードの確認"
          value={inputValues.confirm}
          onChange={onInputChange}
        />
      </div>
      <div className="form-group">
        {errors.name && <p className="invalid-text">{errors.name}</p>}
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="氏名"
          value={inputValues.name}
          onChange={onInputChange}
        />
      </div>
      <div className="form-group">
        {errors.displayName && (
          <p className="invalid-text">{errors.displayName}</p>
        )}
        <input
          type="text"
          className="form-control"
          name="displayName"
          placeholder="表示名"
          value={inputValues.displayName}
          onChange={onInputChange}
        />
      </div>
      <button type="submit" className="btn btn-primary btn-block">
        アカウント作成
      </button>
      <div className="terms-and-privacy">
        アカウントを作成すると、
        {appEnv.termsUrl ? (
          <a
            href={appEnv.termsUrl}
            className="link-primary"
            target="_blank"
            rel="noreferrer">
            利用規約
          </a>
        ) : (
          <Link to="/terms" className="link-primary">
            利用規約
          </Link>
        )}
        、及び
        <br />
        {appEnv.privacyUrl ? (
          <a
            href={appEnv.privacyUrl}
            className="link-primary"
            target="_blank"
            rel="noreferrer">
            プライバシーポリシー
          </a>
        ) : (
          <Link to="/privacy-policy" className="link-primary">
            プライバシーポリシー
          </Link>
        )}
        に同意したことになります。
      </div>
    </form>
  );
};

export default Signup;
