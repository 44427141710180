import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { login } from '../../store/actions/user';
import { isValidEmail } from '../../utils/validation';
import DeviceDownloads from '../../components/DeviceDownloads';

import './styles.scss';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    if (!isValidEmail(inputValues.email) || !inputValues.password) {
      setError('いずれかの入力項目が間違っています');
      return false;
    }

    setError('');
    return true;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [isSubmitted, inputValues, validate]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(
        login(inputValues, (res) =>
          history.push(res.isAdmin ? '/admin/appenv' : '/'),
        ),
      );
    }
  };

  return (
    <div className="login-page sm-content">
      <div className="logo">
        <Link to="/">
          <img src="/assets/images/logo4x.png" alt="" />
        </Link>
      </div>

      <form onSubmit={onSubmit}>
        <div className="wide-content mb-5">
          <h4 className="text-muted">サインイン</h4>
        </div>
        {error && <p className="text-center col-red mb-1">{error}</p>}
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="メールアドレス"
            value={inputValues.email}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="パスワード"
            value={inputValues.password}
            onChange={onInputChange}
          />
          <div className="forgot-password">
            <Link to="/forgot-password" className="link-secondary">
              パスワードを忘れた場合
            </Link>
          </div>
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          サインイン
        </button>
        <Link to="/signup" className="btn btn-outline-primary btn-block">
          新規登録はこちら
        </Link>
      </form>

      <div className="wide-content mt-5">
        <h4 className="text-muted mb-4">各種ダウンロード</h4>
      </div>
      <DeviceDownloads />
    </div>
  );
};

export default Login;
