import { Link } from 'react-router-dom';

import './styles.scss';
import AvatarIcon from './avatar.png';

const Header = ({ center = true, userName = '' }) => (
  <div className={`container-fluid header${center ? ' center' : ''}`}>
    <Link to="/">
      <img className="logo" src="/assets/images/logo4x.png" alt="" />
    </Link>
    {!center && (
      <div className="d-flex align-items-center">
        <span className="mr-2">
          <b>{userName}</b>
        </span>
        <Link to="/">
          <img className="logo" src={AvatarIcon} alt="Avatar" />
        </Link>
      </div>
    )}
  </div>
);

export default Header;
