import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { createRequest } from '../../utils/api';
import {
  USER_LOGIN,
  CHECK_SESSION,
  USER_CREATE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  USER_LOGOUT,
  UPDATE_USER,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_CARD,
  UPDATE_SUBSCRIPTION,
  CONFIRM_EMAIL,
  APPLY_COUPON,
  CHECK_REGISTERED,
  UPDATE_ONE_DAY_SUBSCRIPTION,
} from '../../config';
import { setSentryTag } from '../../utils/sentry';
import { IUser } from '../reducers/user';

export const actionTypes = {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

  USER_CONFIRM_REQUEST: 'USER_CONFIRM_REQUEST',
  USER_CONFIRM_FAILURE: 'USER_CONFIRM_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  USER_LOGOUT: 'USER_LOGOUT',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  UPDATE_REGISTER_STATE_REQUEST: 'UPDATE_REGISTER_SATE_REQUEST',
  UPDATE_REGISTER_STATE_SUCCESS: 'UPDATE_REGISTER_SATE_SUCCESS',
  UPDATE_REGISTER_STATE_FAILURE: 'UPDATE_REGISTER_SATE_FAILURE',

  UPDATE_CARD_REQUEST: 'UPDATE_CARD_REQUEST',
  UPDATE_CARD_SUCCESS: 'UPDATE_CARD_SUCCESS',
  UPDATE_CARD_FAILURE: 'UPDATE_CARD_FAILURE',
};

export const login = (
  {
    email,
    password,
  }: {
    email: string;
    password: string;
  },
  cb?: (res: any) => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.USER_LOGIN_REQUEST });

  const { response, error } = await createRequest(USER_LOGIN, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (!error && response.success) {
    const user: IUser = response.user;
    user.clipDevice = response.clipDevice?.deviceId;
    user.clipExpire = response.clipDevice?.expire;

    if (response.token) {
      localStorage.setItem('token', response.token);
    }

    setSentryTag(user);
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
      payload: user,
    });
    cb && cb(user);
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.USER_LOGIN_FAILURE });
  }
};

export const checkSession = () => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { error, response } = await createRequest(CHECK_SESSION, {
    method: 'GET',
  });

  if (!error && response.success) {
    const user: IUser = response.user;
    user.clipDevice = response.clipDevice?.deviceId;
    user.clipExpire = response.clipDevice?.expire;

    setSentryTag(user);
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
      payload: user,
    });
  } else {
    toast.error(error || response.message);
    // remove expired token
    localStorage.removeItem('token');
    dispatch({ type: actionTypes.USER_LOGIN_FAILURE });
  }
};

export const checkRegistered = (cb?: (res: any) => void) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: actionTypes.UPDATE_REGISTER_STATE_REQUEST });

  const { error, response } = await createRequest(CHECK_REGISTERED, {
    method: 'GET',
  });

  if (!error && response.success) {
    dispatch({
      type: actionTypes.UPDATE_REGISTER_STATE_SUCCESS,
      payload: {
        registered: response.resellers && response.resellers.length > 0,
      },
    });
    cb && cb(response.resellers && response.resellers.length > 0);
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_REGISTER_STATE_FAILURE });
  }
};

export const register = (
  {
    email,
    password,
    name,
    displayName,
  }: {
    email: string;
    password: string;
    name: string;
    displayName: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.USER_REGISTER_REQUEST });

  const { response, error } = await createRequest(USER_CREATE, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      name,
      displayName,
    }),
  });

  if (!error && response.success) {
    const toastMessage =
      email +
      ' に認証メールを送信しました。認証URLからメールアドレスの認証を行ってください。';

    dispatch({ type: actionTypes.USER_REGISTER_SUCCESS });
    toast.success(toastMessage);
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.USER_REGISTER_FAILURE });
  }
};

export const confirmEmail = (
  { token }: { token: string | null },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  if (!token) {
    return toast.error(
      'トークンが不明です。もういちど初めから認証手順を行ってください。',
    );
  }

  dispatch({ type: actionTypes.USER_CONFIRM_REQUEST });

  const { response, error } = await createRequest(CONFIRM_EMAIL, {
    method: 'POST',
    body: JSON.stringify({ token }),
  });

  if (!error && response.success) {
    const user = response.user;

    if (response.token) {
      localStorage.setItem('token', response.token);
    }

    setSentryTag(user);
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
      payload: user,
    });
    toast.success('メールの認証に成功しました。');
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.USER_CONFIRM_FAILURE });
  }
};

export const forgotPassword = (
  { email }: { email: string },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.FORGOT_PASSWORD_REQUEST });

  const { response, error } = await createRequest(FORGOT_PASSWORD, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  if (!error && response.success) {
    const toastMessage =
      email +
      ' にパスワード再設定用のURLを送信しました。メールボックスをご確認ください。';

    dispatch({ type: actionTypes.FORGOT_PASSWORD_SUCCESS });
    toast.success(toastMessage);
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE });
  }
};

export const resetPassword = (
  {
    token,
    password,
  }: {
    token: string | null;
    password: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  if (!token) {
    return toast.error(
      'トークンが不明です。もういちど初めから認証手順を行ってください。',
    );
  }

  dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST });

  const { response, error } = await createRequest(RESET_PASSWORD, {
    method: 'POST',
    body: JSON.stringify({ token, password }),
  });

  if (!error && response.success) {
    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
    toast.success('パスワードを変更しました。');
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE });
  }
};

export const logout = (forced = true) => {
  localStorage.removeItem('token');

  if (forced) {
    createRequest(USER_LOGOUT, {
      method: 'POST',
    });
  }

  return {
    type: actionTypes.USER_LOGOUT,
  };
};

export const updateUser = (
  data: {
    name?: string;
    displayName?: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { response, error } = await createRequest(UPDATE_USER, {
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!error && response.success) {
    const user = response.user;

    setSentryTag(user);
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: user,
    });
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const updateEmail = (
  {
    email,
    password,
  }: {
    email: string;
    password: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { response, error } = await createRequest(UPDATE_EMAIL, {
    method: 'PUT',
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (!error && response.success) {
    const user = response.user;
    const toastMessage =
      email +
      ' に認証メールを送信しました。認証URLから新規メールアドレスの認証を行ってください。';

    setSentryTag(user);
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: user,
    });
    toast.success(toastMessage);
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const updatePassword = (
  {
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { error, response } = await createRequest(UPDATE_PASSWORD, {
    method: 'PUT',
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  });

  if (!error && response.success) {
    dispatch({ type: actionTypes.UPDATE_USER_SUCCESS });
    toast.success('パスワードを変更しました。');
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const updateCard = (
  {
    card_token,
  }: {
    card_token: string;
  },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });
  const { error, response } = await createRequest(UPDATE_CARD, {
    method: 'POST',
    body: JSON.stringify({
      card_token,
    }),
  });

  if (!error && response.success) {
    dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, payload: response.user });
    toast.success('お支払い情報を変更しました。');
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const updateSubscription = (
  { planId }: { planId: string },
  cb?: (message?: string) => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { error, response } = await createRequest(UPDATE_SUBSCRIPTION, {
    method: 'POST',
    body: JSON.stringify({ planId }),
  });

  if (!error && response.success) {
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: response.user,
    });
    cb && cb(response.message);
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const updateOneDaySubscription = (
  { planId }: { planId: string },
  cb?: (message?: string) => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const oneDayPlanDuration = 1;
  const { error, response } = await createRequest(UPDATE_ONE_DAY_SUBSCRIPTION, {
    method: 'POST',
    body: JSON.stringify({ duration: oneDayPlanDuration, planId }),
  });

  if (!error && response.success) {
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: response.user,
    });
    cb && cb(response.message);
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};

export const applyCoupon = (
  { code }: { code: string },
  cb?: () => void,
) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  const { error, response } = await createRequest(APPLY_COUPON, {
    method: 'POST',
    body: JSON.stringify({ code }),
  });

  if (!error && response.success) {
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: {
        benefit: response.benefit,
        expire: response.expire,
      },
    });
    // trigger registered state verification
    dispatch({
      type: actionTypes.UPDATE_REGISTER_STATE_SUCCESS,
      payload: { registered: undefined },
    });
    cb && cb();
  } else {
    toast.error(error || response.message);
    dispatch({ type: actionTypes.UPDATE_USER_FAILURE });
  }
};
