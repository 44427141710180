import { useCallback } from 'react';

export interface CouponType {
  name: string;
  duration: number;
  durationUnit: string;
  plan: string;
  reseller?: {
    id: number;
    name: string;
  };
}

export default function CouponTypeItem({
  item,
  onClick,
}: {
  item: CouponType;
  onClick?: (couponName: string) => void;
}) {
  const handleClick = useCallback(() => {
    onClick && onClick(item.name);
  }, [item.name, onClick]);

  return (
    <div className="coupon-type-item" onClick={handleClick}>
      <span className="name">{item.name}</span>{' '}
      <span className="disabled">
        {item.duration} {item.durationUnit}
      </span>{' '}
      {item.reseller?.name}
    </div>
  );
}
