import { Features } from '../store/reducers/user';

export type IPlan = Record<Features, boolean>;

const payjpPlanId: {
  [id: string]: IPlan;
} = {
  '0': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 0,
  },
  '1': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: true,
    // price: 980,
  },
  '2': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: true,
    // meetingMemo: false,
    // price: 980,
  },
  '3': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: true,
    // meetingMemo: true,
    // price: 1960,
  },
  '4': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: true,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 580,
  },
  '5': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: true,
    allLanguageTranslate: false,
    // meetingMemo: true,
    // price: 1560,
  },
  '6': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 0,
  },
  '7': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 0,
  },
  '8.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 280,
  },
  '9.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: true,
    // price: 1260,
  },
  '10.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: false,
    allLanguageTranslate: true,
    // meetingMemo: false,
    // price: 1260,
  },
  '11.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: false,
    allLanguageTranslate: true,
    // meetingMemo: true,
    // price: 2240,
  },
  '12.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: true,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 860,
  },
  '13.1': {
    speechToText: true,
    voiceMemo: true,
    englishTranslate: true,
    allLanguageTranslate: false,
    // meetingMemo: true,
    // price: 1840,
  },
  '14.1': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 0,
  },
  '15.1': {
    speechToText: true,
    voiceMemo: false,
    englishTranslate: false,
    allLanguageTranslate: false,
    // meetingMemo: false,
    // price: 0,
  },
};

export default payjpPlanId;

export const convertPlan = (planId: string) => {
  if (parseFloat(planId) > 7) {
    if (!planId.endsWith('.1')) {
      return `${planId}.1`;
    }
  }
  return planId;
};
