import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../../../store/selectors/user';
import { logout } from '../../../store/actions/user';
import { createRequest } from '../../../utils/api';
// import { plans } from '../../../utils/plans';
import { DELETE_ACCOUNT } from '../../../config';
import { toast } from 'react-toastify';

const CancelAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserInfo);
  // const currentPlan = plans[user!.payjpBenefit || user!.planId];

  const [isDone, setDone] = useState<boolean | null>(null);

  const onBack = () => {
    history.push('/');
  };

  const onCancelAccount = () => {
    createRequest(DELETE_ACCOUNT, { method: 'POST' }).then(
      ({ response, error }) => {
        if (error || !response.success) {
          toast.error('退会出来ませんでした。');
          setDone(null);
        } else {
          history.push('/cancelled', {
            cancelled: true,
          });
          dispatch(logout(false));
        }
      },
    );
  };

  const toPlanChange = () => {
    history.push({
      pathname: '/settings',
      state: { id: 'plan' },
    });
  };

  return (
    <div className="home-page sm-content">
      <h4 className="page-title">退会手続き</h4>

      {user!.payjpPlanId === '0' ? (
        <>
          <div className="user-info">
            <h4>{user!.displayName}</h4>
            <span>{user!.email}</span>
            <span>パスワード：******</span>
            {/* {currentPlan && (
              <span>
                プランの詳細：{currentPlan.name}
                {user!.payjpBenefit ? (
                  <>
                    <del>{currentPlan.price}</del>無料
                  </>
                ) : (
                  currentPlan.price
                )}
              </span>
            )} */}
          </div>

          <div className="settings-list">
            {
              '・ご登録いただいているアカウント及び、ユーザー情報は即座に削除されます。'
            }
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={onBack}>
            戻る
          </button>
          <button
            type="button"
            className="btn btn-outline-primary btn-block"
            disabled={isDone !== null}
            onClick={onCancelAccount}>
            退会
          </button>
        </>
      ) : (
        <>
          <p>
            退会手続きを行うには、プランを「無料プラン」に戻す必要があります。
          </p>
          <p className="cancel-account-link" onClick={toPlanChange}>
            プランの変更へ
          </p>
        </>
      )}
    </div>
  );
};

export default CancelAccount;
