import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateAppEnvironment } from '../../../store/actions/app';
import { getEnvironment, getIsSubmitting } from '../../../store/selectors/app';

import { isValidVersion } from '../../../utils/validation';

interface IError {
  iosVersion?: string;
  androidVersion?: string;
  privacyVersion?: string;
  privacyUrl?: string;
  termsVersion?: string;
  termsUrl?: string;
  noticeVersion?: string;
  noticeUrl?: string;
  iosAppStoreLink?: string;
  androidPlaystoreLink?: string;
}

const PrivacyForm = () => {
  const dispatch = useDispatch();
  const appEnv = useSelector(getEnvironment);
  const isSubmitting = useSelector(getIsSubmitting);
  const [inputValues, setInputValues] = useState({
    iosVersion: appEnv.iosVersion || '',
    androidVersion: appEnv.androidVersion || '',
    privacyVersion: appEnv.privacyVersion || '',
    privacyUrl: appEnv.privacyUrl || '',
    termsVersion: appEnv.termsVersion || '',
    termsUrl: appEnv.termsUrl || '',
    noticeVersion: appEnv.noticeVersion || '',
    noticeUrl: appEnv.noticeUrl || '',
    iosAppStoreLink: appEnv.iosAppStoreLink || '',
    androidPlaystoreLink: appEnv.androidPlaystoreLink || '',
  });
  const [errors, setErrors] = useState<IError>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    const e: IError = {};
    if (!isValidVersion(inputValues.privacyVersion)) {
      e.privacyVersion = 'バージョンは数値（例: 1.4）で入力してください。';
    }
    if (!inputValues.privacyUrl) {
      e.privacyUrl = 'このフィールドは必須です。.';
    }
    if (!isValidVersion(inputValues.termsVersion)) {
      e.termsVersion = 'バージョンは数値（例: 1.4）で入力してください。';
    }
    if (!inputValues.termsUrl) {
      e.termsUrl = 'このフィールドは必須です。.';
    }
    if (!isValidVersion(inputValues.noticeVersion)) {
      e.noticeVersion = 'バージョンは数値（例: 1.4）で入力してください。';
    }
    if (!inputValues.noticeUrl) {
      e.noticeUrl = 'このフィールドは必須です。.';
    }
    if (!inputValues.iosVersion) {
      e.iosVersion = 'このフィールドは必須です。.';
    }
    if (!inputValues.androidVersion) {
      e.androidVersion = 'このフィールドは必須です。.';
    }
    if (!inputValues.iosAppStoreLink) {
      e.iosAppStoreLink = 'このフィールドは必須です。.';
    }
    if (!inputValues.androidPlaystoreLink) {
      e.androidPlaystoreLink = 'このフィールドは必須です。.';
    }

    setErrors(e);
    return Object.values(e).length === 0;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, inputValues]);

  useEffect(() => {
    setInputValues({
      iosVersion: appEnv.iosVersion || '',
      androidVersion: appEnv.androidVersion || '',
      privacyVersion: appEnv.privacyVersion || '',
      privacyUrl: appEnv.privacyUrl || '',
      termsVersion: appEnv.termsVersion || '',
      termsUrl: appEnv.termsUrl || '',
      noticeVersion: appEnv.noticeVersion || '',
      noticeUrl: appEnv.noticeUrl || '',
      iosAppStoreLink: appEnv.iosAppStoreLink || '',
      androidPlaystoreLink: appEnv.androidPlaystoreLink || '',
    });
  }, [appEnv]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(updateAppEnvironment(inputValues));
    }
  };

  return (
    <>
      <h4 className="page-title">Application Environment Management</h4>
      <form className="admin-form" onSubmit={onSubmit}>
        <div className="form-group">
          <label>iOSバージョン</label>
          <input
            type="text"
            className="form-control"
            name="iosVersion"
            placeholder="iOSバージョン"
            value={inputValues.iosVersion}
            onChange={onInputChange}
          />
          {errors.iosVersion && (
            <p className="invalid-text">{errors.iosVersion}</p>
          )}
        </div>

        <div className="form-group">
          <label>アンドロイド版</label>
          <input
            type="text"
            className="form-control"
            name="androidVersion"
            placeholder="アンドロイド版"
            value={inputValues.androidVersion}
            onChange={onInputChange}
          />
          {errors.androidVersion && (
            <p className="invalid-text">{errors.androidVersion}</p>
          )}
        </div>

        <div className="form-group">
          <label>プライバシーポリシーのバージョン</label>
          <input
            type="text"
            className="form-control"
            name="privacyVersion"
            placeholder="プライバシーポリシーのバージョン"
            value={inputValues.privacyVersion}
            onChange={onInputChange}
          />
          {errors.privacyVersion && (
            <p className="invalid-text">{errors.privacyVersion}</p>
          )}
        </div>

        <div className="form-group">
          <label>プライバシーポリシーのURL</label>
          <input
            type="text"
            className="form-control"
            name="privacyUrl"
            placeholder="プライバシーポリシーのURL"
            value={inputValues.privacyUrl}
            onChange={onInputChange}
          />
          {errors.privacyUrl && (
            <p className="invalid-text">{errors.privacyUrl}</p>
          )}
        </div>

        <div className="form-group">
          <label>利用規約のバージョン</label>
          <input
            type="text"
            className="form-control"
            name="termsVersion"
            placeholder="利用規約のバージョン"
            value={inputValues.termsVersion}
            onChange={onInputChange}
          />
          {errors.termsVersion && (
            <p className="invalid-text">{errors.termsVersion}</p>
          )}
        </div>

        <div className="form-group">
          <label>利用規約のURL</label>
          <input
            type="text"
            className="form-control"
            name="termsUrl"
            placeholder="利用規約のURL"
            value={inputValues.termsUrl}
            onChange={onInputChange}
          />
          {errors.termsUrl && <p className="invalid-text">{errors.termsUrl}</p>}
        </div>

        <div className="form-group">
          <label>注意のバージョン</label>
          <input
            type="text"
            className="form-control"
            name="noticeVersion"
            placeholder="注意のバージョン"
            value={inputValues.noticeVersion}
            onChange={onInputChange}
          />
          {errors.noticeVersion && (
            <p className="invalid-text">{errors.noticeVersion}</p>
          )}
        </div>

        <div className="form-group">
          <label>注意のURL</label>
          <input
            type="text"
            className="form-control"
            name="noticeUrl"
            placeholder="注意のURL"
            value={inputValues.noticeUrl}
            onChange={onInputChange}
          />
          {errors.noticeUrl && (
            <p className="invalid-text">{errors.noticeUrl}</p>
          )}
        </div>

        <div className="form-group">
          <label>AppStoreのURL</label>
          <input
            type="text"
            className="form-control"
            name="iosAppStoreLink"
            placeholder="AppStoreのURL"
            value={inputValues.iosAppStoreLink}
            onChange={onInputChange}
          />
          {errors.iosAppStoreLink && (
            <p className="invalid-text">{errors.iosAppStoreLink}</p>
          )}
        </div>

        <div className="form-group">
          <label>PlayStoreのURL</label>
          <input
            type="text"
            className="form-control"
            name="androidPlaystoreLink"
            placeholder="PlayStoreのURL"
            value={inputValues.androidPlaystoreLink}
            onChange={onInputChange}
          />
          {errors.androidPlaystoreLink && (
            <p className="invalid-text">{errors.androidPlaystoreLink}</p>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={isSubmitting}>
          {isSubmitting ? '保存しています ...' : '保存'}
        </button>
      </form>
    </>
  );
};

export default PrivacyForm;
