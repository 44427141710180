import { useLocation } from 'react-router-dom';

import DisplayName from './components/DisplayName';
import Name from './components/Name';
import Email from './components/Email';
import Password from './components/Password';
import Payment from './components/Payment';
import Coupon from './components/Coupon';
import Plan from './components/Plan';
import CancelAccount from './components/CancelAccount';
import Download from './components/Download';

import './styles.scss';

const Settings = () => {
  const location: any = useLocation();
  let Component;

  if (location.state && location.state.id) {
    switch (location.state.id) {
      case 'displayName':
        Component = DisplayName;
        break;
      case 'name':
        Component = Name;
        break;
      case 'email':
        Component = Email;
        break;
      case 'password':
        Component = Password;
        break;
      case 'payment':
        Component = Payment;
        break;
      case 'coupon':
        Component = Coupon;
        break;
      case 'plan':
        Component = Plan;
        break;
      case 'cancelAccount':
        Component = CancelAccount;
        break;
      case 'download':
        Component = Download;
        break;
      default:
        break;
    }
  }

  return (
    <div className="settings-page sm-content">{Component && <Component />}</div>
  );
};

export default Settings;
