import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updateCard } from '../../../store/actions/user';
import { createRequest } from '../../../utils/api';
import { GET_CARD } from '../../../config';
import { PayjpElement } from '../../../react-app-env';

interface ICardInfo {
  type: string;
  last4: string;
}

const Payment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cardInfo, setCardInfo] = useState<ICardInfo | null>(null);
  const [payjpLoading, setPayjpLoading] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const numberElement = useRef<PayjpElement | undefined>(undefined);
  const expiryElement = useRef<PayjpElement | undefined>(undefined);
  const cvcElement = useRef<PayjpElement | undefined>(undefined);

  useEffect(() => {
    let unmounted = false;
    createRequest(GET_CARD, {
      method: 'GET',
    }).then(({ response, error }) => {
      if (response && response.success && !unmounted) {
        setCardInfo(response.paymentMethod);
      }
    });
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    const style = {
      base: {
        backgroundColor: '#fff',
      },
    };
    const elements = window.payjp.elements();
    numberElement.current = elements.create('cardNumber', { style: style });
    expiryElement.current = elements.create('cardExpiry', { style: style });
    cvcElement.current = elements.create('cardCvc', { style: style });
    numberElement.current.mount('#number-form');
    expiryElement.current.mount('#expiry-form');
    cvcElement.current.mount('#cvc-form');
    setPayjpLoading(false);
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (window.payjp && numberElement.current) {
      try {
        const response = await window.payjp.createToken(numberElement.current);
        if (response.error) {
          toast.error(response?.error.message || 'エラーが発生しました！');
        } else {
          dispatch(
            updateCard({ card_token: response.id }, () => {
              setLoading(false);
            }),
          );
        }
      } catch (error) {
        toast.error('エラーが発生しました！');
      }
    }
    setLoading(false);
  };

  const onBack = () => {
    history.push('/');
  };

  return (
    <>
      <h4 className="page-title">お支払い情報の変更</h4>
      <form onSubmit={onSubmit} className="payjp-form">
        {payjpLoading && <div className="payjp-loader">処理中。。。</div>}
        <label>
          現在のカード：
          {cardInfo &&
            `${cardInfo.type.toUpperCase()} xxxx-xxxx-xxxx-${cardInfo.last4}`}
        </label>
        <div className="payjp-input">
          <div id="number-form" className="form-control"></div>
          <div id="expiry-form" className="form-control"></div>
          <div id="cvc-form" className="form-control"></div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={isLoading}>
          保存
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={onBack}>
          キャンセル
        </button>
      </form>
    </>
  );
};

export default Payment;
