import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAppEnvironment } from '../../store/actions/app';
import { getEnvironment } from '../../store/selectors/app';

import './styles.scss';

const Footer = () => {
  const dispatch = useDispatch();
  const appEnv = useSelector(getEnvironment);

  useEffect(() => {
    dispatch(getAppEnvironment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid footer">
      <div className="row">
        <div className="col-12 d-flex">
          {appEnv.privacyUrl ? (
            <a
              href={appEnv.privacyUrl}
              className="link-default"
              target="_blank"
              rel="noreferrer">
              プライバシー
            </a>
          ) : (
            <Link to="/privacy-policy" className="link-default">
              プライバシー
            </Link>
          )}

          {appEnv.noticeUrl ? (
            <a
              href={appEnv.noticeUrl}
              className="link-default"
              target="_blank"
              rel="noreferrer">
              特定商取引法に基づく表示
            </a>
          ) : (
            <Link to="/notice" className="link-default">
              特定商取引法に基づく表示
            </Link>
          )}
        </div>
        <div className="col-12 col-white text-center">
          ©︎2020 Donut Robotics Co., Ltd.
        </div>
      </div>
    </div>
  );
};

export default Footer;
