export function getCurrentEnvironment() {
  const { hostname } = window.location;

  if (hostname.endsWith('.netlify.app')) {
    return 'netlify';
  } else if (hostname === 'localhost' || hostname.startsWith('192.168')) {
    return 'local';
  } else if (hostname.endsWith('web.app') || hostname.endsWith('c-face.jp')) {
    return 'staging';
  } else {
    throw new Error('unknown environment');
  }
}
