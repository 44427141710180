export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// APIs that related with user management
export const USER_LOGIN = '/user/signin';
export const CHECK_SESSION = '/user/checkSession';
export const USER_LOGOUT = '/user/signout';
export const USER_CREATE = '/user/create';
export const CONFIRM_EMAIL = '/user/confirmEmail';
export const FORGOT_PASSWORD = '/user/forgotPassword';
export const RESET_PASSWORD = '/user/resetPassword';
export const UPDATE_USER = '/user/update';
export const UPDATE_EMAIL = '/user/updateEmail';
export const UPDATE_PASSWORD = '/user/updatePassword';
export const DELETE_ACCOUNT = '/user/cancel';
export const CHECK_REGISTERED = '/user/registered';

// APIs that related with subscriptions
export const UPDATE_CARD = '/subscription/card';
export const GET_CARD = '/subscription/card';
export const UPDATE_SUBSCRIPTION = '/subscription';
export const UPDATE_ONE_DAY_SUBSCRIPTION = '/subscription/applyShortPlan';
export const APPLY_COUPON = '/subscription/applyCoupon';
export const GET_COUPON = '/subscription/coupon';

// APIs that related with app environment
export const GET_ENVIONMENT = '/app/beta/environment';
export const UPDATE_ENVIRONMENT = '/app/environment';
export const IMPORT_COUPON = '/app/coupon';

// Product Serial management
export const GET_BATCH_STATUS = '/serial/status';
export const GET_COUPON_TYPES = '/serial/couponTypes';
export const ACTIVATE_BATCH = '/serial/activate';

export const IMPORT_CLIP_EAR = '/transceiver/devices';
