import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../components';

import { confirmEmail } from '../../store/actions/user';

const EmailConfirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    dispatch(confirmEmail({ token }, () => history.push('/')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout withHeader>
      <div className="sm-content">
        <h4 className="page-title">少々お待ちください ...</h4>
      </div>
    </Layout>
  );
};

export default EmailConfirm;
