import { Link } from 'react-router-dom';

import './styles.scss';
import CfaceBtn from './dlc-cface.png';
import ClipBtn from './dlc-clip.png';

const DeviceDownloads = () => {
  return (
    <div className="dlc-btn-container d-flex justify-content-between">
      <Link
        to={{ pathname: 'download/clip', state: { device: 'clip' } }}
        className="dlc-page-btn">
        <img src={ClipBtn} alt="CFACE download" />
      </Link>
      <Link
        to={{ pathname: 'download/cface', state: { device: 'cface' } }}
        className="dlc-page-btn hidden">
        <img src={CfaceBtn} alt="CFACE download" />
      </Link>
    </div>
  );
};

export default DeviceDownloads;
