import React from 'react';

import './styles.scss';

function PlanItem({
  data,
  isOneDayPlan,
  isChecked,
  isDisabled,
  children,
  buttonPressed,
}: {
  data: any;
  isOneDayPlan: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
  children: React.ReactElement;
  buttonPressed: (id: string, isChecked: boolean) => void;
}) {
  return (
    <div
      className="plan-item"
      onClick={() => buttonPressed(data.id, isChecked)}>
      <div className="checkbox">
        <input
          type="checkbox"
          disabled={isDisabled}
          checked={isChecked}
          readOnly
        />
      </div>
      <div
        className={
          isChecked
            ? isDisabled
              ? 'plan-item-content plan-item-content-disabled'
              : 'plan-item-content plan-item-content-selected'
            : 'plan-item-content'
        }>
        <div className="left-content">
          <div className="plan-name">
            <span className="font-sm font-weight-bold">{data.name}</span>
          </div>
        </div>
        <div className="right-content">
          {children}
          {data.price && data.oneDayPrice ? (
            <span className="font-sm-nowrap font-weight-bold">
              {`${isOneDayPlan ? data.oneDayPrice + '¥' : data.price + '¥/月'}`}
            </span>
          ) : (
            <span className="font-sm-nowrap font-weight-bold">無料</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlanItem;
