import { useState } from 'react';
import { toast } from 'react-toastify';
import { createRequest } from '../../../utils/api';

export const useClipEarDevice = () => {
  const [clipEarDeviceId, setClipEarDeviceId] = useState('');

  const onAddClipEarDevice = async () => {
    try {
      const { response } = await createRequest('/transceiver/device/register', {
        method: 'POST',
        body: JSON.stringify({
          deviceId: clipEarDeviceId,
        }),
      });
      if (response.success) {
        toast.success('ClipEarを追加しました');
      } else {
        const errorMessage = response.message;
        let errorDescription = '何かがうまくいかなかった。';
        switch (errorMessage) {
          case 'ALREADY_DEVICE_ID_USED':
            errorDescription = '既に登録済みの製品番号です。';
            break;
          case 'WRONG_DEVICE_ID':
            errorDescription = '製品番号が間違っています';
            break;
        }
        toast.error(errorDescription);
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const content = (
    <div>
      <label className="form-label">clip EAR をお持ちの方</label>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="製品番号を入力"
          value={clipEarDeviceId}
          onChange={(e) => setClipEarDeviceId(e.target.value)}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary btn-block"
        disabled={!clipEarDeviceId}
        onClick={onAddClipEarDevice}>
        登録する
      </button>
    </div>
  );

  return content;
};
