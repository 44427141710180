export const isValidEmail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const isValidPassword = (value: string) => value.length >= 6;

export const isValidExpiry = (value: string) =>
  /^(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})$/.test(value);

export const isValidCVC = (value: string) => /^[0-9]{3,4}$/.test(value);

export const isValidVersion = (value: string) => /^\d+(\.\d{1,})?$/.test(value);
