import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { isValidEmail } from '../../../utils/validation';

import { getUserInfo } from '../../../store/selectors/user';
import { updateEmail } from '../../../store/actions/user';

interface IError {
  email?: string;
  password?: string;
}

const Email = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserInfo);
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<IError>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    const e: IError = {};
    if (!isValidEmail(inputValues.email)) {
      e.email = 'メールアドレス形式ではありません';
    }
    if (!inputValues.password) {
      e.password = 'パスワードが正しくありません';
    }

    setErrors(e);
    return Object.values(e).length === 0;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, inputValues]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(updateEmail(inputValues, () => history.push('/')));
    }
  };

  const onBack = () => {
    history.push('/');
  };

  return (
    <>
      <h4 className="page-title">メールアドレスの変更</h4>

      <form onSubmit={onSubmit}>
        <label>現在のメールアドレス：{user?.email}</label>

        <div className="form-group">
          {errors.email && <p className="invalid-text">{errors.email}</p>}
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="新しいメールアドレス"
            value={inputValues.email}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          {errors.password && <p className="invalid-text">{errors.password}</p>}
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="現在のパスワード"
            value={inputValues.password}
            onChange={onInputChange}
          />
          <div className="forgot-password">
            <Link to="/forgot-password" className="link-secondary">
              パスワードを忘れた場合
            </Link>
          </div>
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          保存
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={onBack}>
          キャンセル
        </button>
      </form>
    </>
  );
};

export default Email;
