import { useCallback, useMemo, useEffect, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {
  ACTIVATE_BATCH,
  GET_BATCH_STATUS,
  GET_COUPON_TYPES,
  IMPORT_COUPON,
} from '../../../config';
import { createRequest } from '../../../utils/api';
import CouponTypeItem, { CouponType } from './CouponTypeItem';

interface SerialInfo {
  activated: boolean;
  type: string;
  activatedType: string;
  barCode: string;
}

declare global {
  interface Window {
    HACK_BATCH_SIZE: number;
  }
}

export default function Serial() {
  const [serialData, setSerialData] = useState<string | null>(null);
  // const [progress, setProgress] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  // C-FACE box
  const [searchCode, setSearchCode] = useState('');
  const [boxInfo, setBoxInfo] = useState<SerialInfo | null>(null);
  const [searching, setSearching] = useState(false);

  // coupon type list
  const [couponTypes, setCouponTypes] = useState<CouponType[]>([]);
  const [showModal, setShowModal] = useState(false);

  const onFileSelect = useCallback((e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0) {
      // check file type
      if (!files[0].name.toLocaleLowerCase().endsWith('.csv')) {
        toast.error('CSVファイルを選択してください。');
        target.value = '';
      } else {
        // load file content
        const reader = new FileReader();
        // setup callback
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const text = e.target?.result;
          setSerialData(text ? (text as string) : null);
        };
        // start reading
        reader.readAsText(files[0]);
      }
    }
  }, []);

  const onImportSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      createRequest(IMPORT_COUPON, {
        method: 'POST',
        body: JSON.stringify({
          couponData: serialData,
          raw: true,
          batchSize: window.HACK_BATCH_SIZE || 25, // 24 is the default batch size
        }),
      }).then(({ response, error }) => {
        if (error || !response.success) {
          toast.error('値に誤りが含まれています。インポートに失敗しました。');
        } else {
          toast.success('インポートに成功しました。.');
        }
        setLoading(false);
      });
    },
    [serialData],
  );

  const handleProductSearch = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (searchCode) {
        setSearching(true);
        createRequest(`${GET_BATCH_STATUS}?code=${searchCode}`, {
          method: 'GET',
        }).then(({ response, error }) => {
          if (!error && response.success) {
            setBoxInfo(response.serialInfo);
          }
          setSearching(false);
        });
      }
    },
    [searchCode],
  );

  // when box update button is clicked, show coupon type modal
  const handleBoxUpdate = useCallback((e: FormEvent) => {
    e.preventDefault();
    setShowModal(true);
  }, []);

  const handleBackToBoxSearch = useCallback(() => {
    setBoxInfo(null);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  // update a box of C-FACE to the selected coupon type
  const handleCouponTypeClick = useCallback(
    (couponName: string) => {
      setShowModal(false);
      createRequest(ACTIVATE_BATCH, {
        method: 'POST',
        body: JSON.stringify({
          code: boxInfo?.barCode,
          type: couponName,
        }),
      }).then(({ response, error }) => {
        // get new box info
        const newInfo = response.serialInfo as SerialInfo | null;
        if (error || !response.success || !newInfo || !newInfo.activated) {
          toast.error(
            `${boxInfo?.barCode}を${couponName}タイプに設定できませんでした。`,
          );
        } else {
          toast.success(
            `${newInfo.barCode}が${newInfo.activatedType}タイプに設定されました。`,
          );
        }
        // show new box info
        setBoxInfo(newInfo);
      });
    },
    [boxInfo?.barCode],
  );

  const couponCount = useMemo(() => {
    return serialData?.split('\n').filter((line, i) => i > 0 && line.length > 0)
      .length;
  }, [serialData]);

  // load couponTypes on start up
  useEffect(() => {
    createRequest(GET_COUPON_TYPES, {
      method: 'GET',
    }).then(({ response, error }) => {
      if (!error && response.success) {
        setCouponTypes(response.couponTypes);
      }
    });
  }, []);

  return (
    <>
      <h4 className="page-title">製品番号管理</h4>
      <h5 className="form-title admin-form">製品番号インポート</h5>
      <form onSubmit={onImportSubmit}>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <label className="form-check-label mr-4">
              製品番号のCSVファイルをアップロードしてください。
            </label>
          </div>
        </div>

        <div className="form-group">
          <input type="file" onChange={onFileSelect} />
        </div>

        {typeof couponCount === 'number' && (
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="form-check-label mr-4">
                {`${couponCount} 個の製品番号が読み込まれました。`}
              </label>
            </div>
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={!serialData || loading}>
          {loading ? 'インポート中。。。' : 'インポート'}
        </button>
      </form>

      <h5 className="form-title mt-5">C-FACE Box 管理</h5>
      {boxInfo ? (
        <form onSubmit={handleBoxUpdate}>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Box No.</label>
            <div className="col-sm-8">
              <span className="form-control">{boxInfo.barCode}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">タイプ</label>
            <div className="col-sm-8">
              <span className="form-control">{boxInfo.type}</span>
            </div>
          </div>
          {boxInfo.activated && (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">特典タイプ</label>
              <div className="col-sm-8">
                <span className="form-control">{boxInfo.activatedType}</span>
              </div>
            </div>
          )}
          <div className="form-group row float-right">
            {!boxInfo.activated && (
              <div className="col-auto">
                <button type="submit" className="btn btn-primary">
                  特典設定
                </button>
              </div>
            )}
            <div className="col-auto" onClick={handleBackToBoxSearch}>
              <button type="button" className="btn btn-secondary">
                戻る
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form className="admin-form" onSubmit={handleProductSearch}>
          <div className="form-group">
            <small className="form-text text-muted mb-2">
              {'【製品番号】又は【クーポンコード】又は【Box No.】で検索'}
            </small>
            <input
              type="text"
              className="form-control"
              placeholder="【製品番号】｜【クーポンコード】｜【Box No.】"
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value.trim())}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={searching}>
            {searching ? '検索中。。。' : 'Boxを検索'}
          </button>
        </form>
      )}

      {/* Modal to select coupon type */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>クーポンタイプをご選択ください。</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {couponTypes.map((type) => (
            <CouponTypeItem
              key={type.name}
              item={type}
              onClick={handleCouponTypeClick}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
