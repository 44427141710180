import React from 'react';

import Header from '../Header';
import Footer from '../Footer';

import './styles.scss';

const Layout = ({
  withHeader,
  headerCenter,
  children,
  userName,
}: {
  withHeader?: boolean;
  headerCenter?: boolean;
  children: React.ReactNode;
  userName?: string;
}) => (
  <>
    {withHeader && <Header center={headerCenter} userName={userName} />}
    <div className="container-fluid main-content">{children}</div>
    <Footer />
  </>
);

export default Layout;
