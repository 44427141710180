const DO_NOT_SHOW_AGAIN = 'doNotShow_coupon';

export const getCouponDoNotShowState = () => {
  return !!localStorage.getItem(DO_NOT_SHOW_AGAIN);
};

export const setCouponDoNotShowState = (doNotShow: boolean = true) => {
  if (doNotShow) {
    localStorage.setItem(DO_NOT_SHOW_AGAIN, 'true');
  } else {
    localStorage.removeItem(DO_NOT_SHOW_AGAIN);
  }
};
