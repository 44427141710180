import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../components';

import { resetPassword } from '../../store/actions/user';

import { isValidPassword } from '../../utils/validation';

import './styles.scss';

interface IError {
  password?: string;
  confirm?: string;
}

const Reset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    password: '',
    confirm: '',
  });
  const [errors, setErrors] = useState<IError>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    const e: IError = {};
    if (!isValidPassword(inputValues.password)) {
      e.password = '6文字以上、40文字以下で入力してください';
    }
    if (inputValues.password !== inputValues.confirm) {
      e.confirm = 'パスワードが一致しません';
    }

    setErrors(e);
    return Object.values(e).length === 0;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, inputValues]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      dispatch(
        resetPassword(
          {
            token,
            password: inputValues.password,
          },
          () => history.push('/login'),
        ),
      );
    }
  };

  return (
    <Layout withHeader>
      <form className="reset-password-page sm-content" onSubmit={onSubmit}>
        <div className="form-group">
          {errors.password && <p className="invalid-text">{errors.password}</p>}
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="パスワード"
            value={inputValues.password}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          {errors.confirm && <p className="invalid-text">{errors.confirm}</p>}
          <input
            type="password"
            className="form-control"
            name="confirm"
            placeholder="パスワード (再入力)"
            value={inputValues.confirm}
            onChange={onInputChange}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          パスワードの再設定
        </button>
      </form>
    </Layout>
  );
};

export default Reset;
