import { BASE_URL } from '../config';

const defaultOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

export function getAuthToken() {
  return localStorage.getItem('token');
}

export async function createRequest<T extends {} = any>(
  url: string,
  options?: RequestInit,
) {
  let response = {} as T;
  let error = null;
  let headers = {
    ...defaultOptions.headers,
    ...options?.headers,
  };
  const token = getAuthToken();

  if (token) {
    headers = { ...headers, authorization: token };
  }

  const initOptions: RequestInit = {
    ...defaultOptions,
    headers,
    ...options,
  };

  try {
    const res = await fetch(BASE_URL + url, initOptions);

    response = await res.json();
  } catch (e) {
    error = e;
    console.error(e);
  }

  return { response, error };
}
