import './styles.scss';

import { useDispatch } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

import { logout } from '../../store/actions/user';

import { Layout } from '../../components';
import BetaVersion from './components/betaVersion';
import PrivacyForm from './components/privacyForm';
import NavBar from './components/NavBar';
import ImportCoupon from './components/importCoupon';
import Serial from './components/serial';
import ImportClipEar from './transceiver/ImportClipEar';

const Privacy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const onLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <Layout withHeader>
      <div className="sm-content admin-page">
        <Route path={`${path}/:segment?`}>
          <NavBar
            root={path}
            paths={[
              {
                segment: '',
                text: 'URL設定',
              },
              {
                segment: 'coupon',
                text: 'クーポン',
              },
              {
                segment: 'serial',
                text: '製品番号',
              },
              {
                segment: 'beta',
                text: 'Beta',
              },
              {
                segment: 'clip_ear',
                text: 'ClipEar',
              },
            ]}
          />
        </Route>

        <Route exact path={path} component={PrivacyForm} />
        <Route exact path={`${path}/coupon`} component={ImportCoupon} />
        <Route exact path={`${path}/serial`} component={Serial} />
        <Route exact path={`${path}/beta`} component={BetaVersion} />
        <Route exact path={`${path}/clip_ear`} component={ImportClipEar} />

        <button
          type="button"
          className="btn btn-outline-primary btn-block mt-5"
          onClick={onLogout}>
          Logout
        </button>
      </div>
    </Layout>
  );
};

export default Privacy;
