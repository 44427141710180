import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkSession } from './store/actions/user';
import { getUserInfo } from './store/selectors/user';
import { getAuthToken } from './utils/api';

export default function useUserInfo() {
  const user = useSelector(getUserInfo);
  const token = getAuthToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && (!user || !user.id)) {
      dispatch(checkSession());
    }
  }, [dispatch, token, user]);

  return { user, token };
}
