import { actionTypes } from '../actions/app';

export interface IApp {
  isSubmitting: boolean;
  env: {
    iosVersion: string;
    androidVersion: string;
    disableBetaVersion: boolean;
    androidPlaystoreLink: string;
    iosAppStoreLink: string;
    privacyVersion: string;
    privacyUrl: string;
    termsVersion: string;
    termsUrl: string;
    noticeVersion: string;
    noticeUrl: string;
  };
}

export const appInitialState: IApp = {
  isSubmitting: false,
  env: {
    iosVersion: '',
    androidVersion: '',
    disableBetaVersion: false,
    androidPlaystoreLink: '',
    iosAppStoreLink: '',
    privacyVersion: '',
    privacyUrl: '',
    termsVersion: '',
    termsUrl: '',
    noticeVersion: '',
    noticeUrl: '',
  },
};

const appReducer = (state = appInitialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_APP_ENV:
      return {
        ...state,
        env: action.payload,
      };
    case actionTypes.UPDATE_ENV_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case actionTypes.UPDATE_ENV_SUCCESS:
      if (action.payload) {
        return {
          isSubmitting: false,
          env: {
            ...state.env,
            ...action.payload,
          },
        };
      }

      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.UPDATE_ENV_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
};

export default appReducer;
