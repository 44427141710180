import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { createRequest } from '../../utils/api';
import { GET_ENVIONMENT, UPDATE_ENVIRONMENT } from '../../config';

export const actionTypes = {
  SET_APP_ENV: 'SET_APP_ENV',
  UPDATE_ENV_REQUEST: 'UPDATE_ENV_REQUEST',
  UPDATE_ENV_SUCCESS: 'UPDATE_ENV_SUCCESS',
  UPDATE_ENV_FAILURE: 'UPDATE_ENV_FAILURE',
};

export const getAppEnvironment = () => async (dispatch: Dispatch) => {
  const { response, error } = await createRequest(GET_ENVIONMENT);

  if (!error && response.success) {
    const payload = response.app;

    dispatch({
      type: actionTypes.SET_APP_ENV,
      payload,
    });
  }
};

export const updateAppEnvironment = (data: {
  disableBetaVersion?: boolean;
  androidPlaystoreLink?: string;
  iosAppStoreLink?: string;
  privacyVersion?: string;
  privacyUrl?: string;
  termsVersion?: string;
  termsUrl?: string;
  noticeVersion?: string;
  noticeUrl?: string;
}) => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_ENV_REQUEST });
  const { response, error } = await createRequest(UPDATE_ENVIRONMENT, {
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!error && response.success) {
    dispatch({
      type: actionTypes.UPDATE_ENV_SUCCESS,
      payload: data,
    });
    toast.success('アプリケーション環境の更新に成功しました。');
  } else {
    dispatch({ type: actionTypes.UPDATE_ENV_FAILURE });
    toast.error(error || response.message);
  }
};
