import { FormEvent, useState } from 'react';
import { createRequest } from '../../../utils/api';
import { IMPORT_CLIP_EAR } from '../../../config';
import { toast } from 'react-toastify';

const ImportClipEar = () => {
  const [deviceIds, setDeviceIds] = useState<string | null>(null);
  // const [progress, setProgress] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const onFileSelect = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0) {
      // check file type
      if (!files[0].name.toLocaleLowerCase().endsWith('.csv')) {
        toast.error('CSVファイルを選択してください。');
        target.value = '';
      } else {
        // load file content
        const reader = new FileReader();
        // setup callback
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const text = e.target?.result;

          setDeviceIds(text ? (text as string) : null);
        };
        // start reading
        reader.readAsText(files[0]);
      }
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    createRequest(IMPORT_CLIP_EAR, {
      method: 'POST',
      body: JSON.stringify({
        deviceIds: deviceIds?.split('\n').map((item) => item.trim()),
      }),
    }).then(({ response, error }) => {
      if (error || !response.success) {
        toast.error('値に誤りが含まれています。インポートに失敗しました。');
      } else {
        toast.success('インポートに成功しました。.');
      }
      setLoading(false);
    });
  };

  const deviceCount = deviceIds
    ?.split('\n')
    .filter((line, i) => line.length > 0).length;

  return (
    <>
      <h4 className="page-title">ClipEar管理</h4>
      <form className="admin-form" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <label className="form-check-label mr-4">
              ClipEarのCSVファイルをアップロードしてください。
            </label>
          </div>
        </div>

        <div className="form-group">
          <input type="file" onChange={onFileSelect} />
        </div>

        {typeof deviceCount === 'number' && (
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="form-check-label mr-4">
                {`${deviceCount} 個のClipEarが読み込まれました。`}
              </label>
            </div>
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={!deviceIds || loading}>
          {loading ? 'インポートしています...' : 'インポート'}
        </button>
      </form>
    </>
  );
};

export default ImportClipEar;
