import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { applyCoupon } from '../../../store/actions/user';
import { createRequest } from '../../../utils/api';
import { GET_COUPON } from '../../../config';
import { getExpire, getUserInfo } from '../../../store/selectors/user';
import { toast } from 'react-toastify';
import payjpPlanId from '../../../utils/payjpPlanId';
import { plans } from '../../../utils/plans';
import { useClipEarDevice } from './useClipEarDevice';
import { setCouponDoNotShowState } from '../../../utils/localStorageUtils';

interface ICoupon {
  plan: string;
  duration: number;
  durationUnit: string;
  reseller?: { id: number; name: string };
}

function isValidCoupon(coupon?: ICoupon | null) {
  return coupon && (coupon.duration || coupon.reseller);
}

const titles = ['製品番号の登録', '詳細確認', '登録完了'];
const confirmButton = ['登録する', '確認', '完了'];

interface IState {
  step: number;
  code: string;
  coupon: ICoupon | null;
  couponInfoLoaded?: boolean;
  isLoading: boolean;
}

const initialState: IState = {
  step: 0,
  code: '',
  coupon: null,
  isLoading: false,
};

interface IAction {
  type: string;
  payload?: any;
}

const ACTION_TYPE = {
  RESET: 'reset',
  INPUT_CODE: 'input',
  FETCH_COUPON: 'fetch',
  FETCH_COUPON_COMPLETED: 'fetch_completed',
  ADVANCE: 'advance',
  SUBMIT_COUPON: 'submit',
};

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPE.INPUT_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case ACTION_TYPE.FETCH_COUPON:
      return {
        ...state,
        isLoading: true,
        couponInfoLoaded: false,
      };
    case ACTION_TYPE.FETCH_COUPON_COMPLETED:
      return {
        ...state,
        isLoading: false,
        couponInfoLoaded: true,
        coupon: action.payload,
      };
    case ACTION_TYPE.ADVANCE:
      return {
        ...state,
        step: state.step + 1,
        isLoading: false,
      };
    case ACTION_TYPE.SUBMIT_COUPON:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPE.RESET:
    default:
      return initialState;
  }
};

const Coupon = () => {
  const user = useSelector(getUserInfo);
  const date = useSelector(getExpire);
  const content = useClipEarDevice();
  const [
    { step, code, coupon, couponInfoLoaded, isLoading },
    dispatchState,
  ] = useReducer(reducer, initialState);

  const history = useHistory();
  const dispatch = useDispatch();

  const toPaymentMethodChange = useCallback(() => {
    history.push({
      pathname: '/settings',
      state: { id: 'payment' },
    });
  }, [history]);

  const onBack = useCallback(() => {
    setCouponDoNotShowState(true);
    history.push('/');
  }, [history]);

  const onCodeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatchState({
      type: ACTION_TYPE.INPUT_CODE,
      payload: e.target.value.trim().toUpperCase(),
    });
  }, []);

  // this is to handle user submit (click on submit button)
  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (step === 0 && code) {
        // fetch coupon info before advancing to the next step
        // but we will not actually advance to the next step here
        dispatchState({ type: ACTION_TYPE.FETCH_COUPON });
      } else if (step === 1) {
        // if we are at step 1 and user click submit, then perform coupon registration
        if (isValidCoupon(coupon)) {
          dispatchState({ type: ACTION_TYPE.SUBMIT_COUPON });
        } else {
          dispatchState({ type: ACTION_TYPE.RESET });
        }
      }
    },
    [code, coupon, step],
  );

  useEffect(() => {
    if (couponInfoLoaded !== undefined) {
      if (!couponInfoLoaded) {
        // fetch coupon info if it is not loaded yet
        createRequest(
          `${GET_COUPON}?${new URLSearchParams({
            code: code,
          })}`,
          {
            method: 'GET',
          },
        ).then(({ response, error }) => {
          dispatchState({
            type: ACTION_TYPE.FETCH_COUPON_COMPLETED,
            payload: response.coupon,
          });
        });
      } else {
        // coupon info has been loaded, decide what to do next
        if (isValidCoupon(coupon) && !coupon!.duration) {
          // immediately submit coupon, since there is no benefit
          dispatchState({ type: ACTION_TYPE.SUBMIT_COUPON });
        } else {
          // advance to next step to show coupon details to user
          dispatchState({ type: ACTION_TYPE.ADVANCE });
        }
      }
    }
  }, [couponInfoLoaded, code, coupon]);

  useEffect(() => {
    if (couponInfoLoaded && isLoading) {
      dispatch(
        applyCoupon({ code }, () => {
          toast.success(
            coupon!.duration
              ? 'クーポンが適用されました、特典の機能がご利用できる状態になりました。'
              : '製品番号登録を完了しました。',
          );
          onBack();
        }),
      );
    }
  }, [couponInfoLoaded, isLoading, code, coupon, dispatch, onBack]);

  const formContent = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <div className="form-group">
            {user!.incomplete ? (
              <>
                <div>
                  決済手続きを完了していません。お支払い方法をご更新ください。
                </div>
                <p className="text-link" onClick={toPaymentMethodChange}>
                  お支払い方法の変更へ
                </p>
              </>
            ) : (
              date && (
                <>
                  <div>
                    特典を利用中に新しい特典情報を入れると、適用中の特典が消去され、新しい特典が即座に適用されます。
                  </div>
                  <label>{`プラン利用期間「${date.getFullYear()}年${
                    date.getMonth() + 1
                  }月${date.getDate()}日 ${date.getHours()}時${date.getMinutes()}分」まで`}</label>
                </>
              )
            )}
            <label className="form-label">C-FACE をお持ちの方</label>
            <input
              type="text"
              className="form-control"
              placeholder="製品番号を入力"
              value={code}
              onChange={onCodeInput}
              disabled={user!.incomplete}
            />
          </div>
        );
      case 1:
        return (
          <div className="plan-update-info">
            {couponInfoLoaded ? (
              isValidCoupon(coupon) ? (
                <>
                  {/* {coupon!.reseller && (
                    <p>
                      <b>{coupon!.reseller.name}</b>会員として登録します。
                    </p>
                  )} */}
                  {(coupon!.duration || null) && (
                    <>
                      {/* <span>{plans[coupon!.plan].name}</span>
                      <span>{`${
                        plans[coupon!.plan].price || 'Free'
                      } → Free`}</span> */}
                      <p>入力した特典の利用できる機能：</p>
                      <div className="plan-update-info font-weight-bold">
                        {Object.values(plans).map((item) => (
                          <span>
                            {payjpPlanId[coupon!.plan] && `${item.name}`}
                          </span>
                        ))}
                      </div>
                      <p>入力した特典の利用期間：</p>
                      <div className="plan-update-info font-weight-bold">
                        <span>{coupon.durationInText}</span>
                      </div>
                      <p>
                        この特典内容を適用すると現在契約中のプランは、一時中断され無料期間終了後に再度再開されます。
                      </p>
                    </>
                  )}
                </>
              ) : (
                <p>この製品番号はご利用いただけません。</p>
              )
            ) : (
              <p>クーポン情報を取得しています。</p>
            )}
          </div>
        );
      default:
        return null;
    }
  }, [
    step,
    user,
    code,
    coupon,
    couponInfoLoaded,
    date,
    onCodeInput,
    toPaymentMethodChange,
  ]);

  return (
    <>
      <h4 className="page-title">{titles[step]}</h4>

      <p className="form-label pt-0 text-center">
        取扱説明書の最後に記載されています
      </p>

      <form onSubmit={onSubmit}>
        {formContent}

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={
            (step === 0 && !code) ||
            (step === 1 && !couponInfoLoaded) ||
            isLoading ||
            user!.incomplete
          }>
          {confirmButton[step]}
        </button>
      </form>
      {content}
      {step < 2 && (
        <div className="align-items-center">
          <button
            className="btn btn-outline-primary btn-block mt-4"
            // style={{ margin: '20px auto' }}
            onClick={onBack}>
            製品番号の登録をスキップする
          </button>
          <div className="text-center mt-2" style={{ fontSize: '0.9rem' }}>
            <small>
              製品番号を登録する画面が繰り返し表示される現象が起きております。
              <br />
              一度登録された方は次回よりスキップしてください。
              <br />
              <br />
              ご不便をおかけしており申し訳ございません。
              <br />
              <br />
              登録が正常に完了したかどうか確認されたい方はマイページの上部に記載されたプランよりご確認ください。
            </small>
          </div>
        </div>
      )}
    </>
  );
};

export default Coupon;
