import { FormEvent, useState } from 'react';
import { createRequest } from '../../../utils/api';
import { IMPORT_COUPON } from '../../../config';
import { toast } from 'react-toastify';

const ImportCoupon = () => {
  const [couponData, setCouponData] = useState<string | null>(null);
  // const [progress, setProgress] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const onFileSelect = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0) {
      // check file type
      if (!files[0].name.toLocaleLowerCase().endsWith('.csv')) {
        toast.error('CSVファイルを選択してください。');
        target.value = '';
      } else {
        // load file content
        const reader = new FileReader();
        // setup callback
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const text = e.target?.result;
          setCouponData(text ? (text as string) : null);
        };
        // start reading
        reader.readAsText(files[0]);
      }
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    createRequest(IMPORT_COUPON, {
      method: 'POST',
      body: JSON.stringify({ couponData }),
    }).then(({ response, error }) => {
      if (error || !response.success) {
        toast.error('値に誤りが含まれています。インポートに失敗しました。');
      } else {
        toast.success('インポートに成功しました。.');
      }
      setLoading(false);
    });
  };

  const couponCount = couponData
    ?.split('\n')
    .filter((line, i) => i > 0 && line.length > 0).length;

  return (
    <>
      <h4 className="page-title">クーポン管理</h4>
      <form className="admin-form" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <label className="form-check-label mr-4">
              クーポンのCSVファイルをアップロードしてください。
            </label>
          </div>
        </div>

        <div className="form-group">
          <input type="file" onChange={onFileSelect} />
        </div>

        {typeof couponCount === 'number' && (
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="form-check-label mr-4">
                {`${couponCount} 個のクーポンが読み込まれました。`}
              </label>
            </div>
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={!couponData || loading}>
          {loading ? 'インポートしています...' : 'インポート'}
        </button>
      </form>
    </>
  );
};

export default ImportCoupon;
