import { combineReducers } from 'redux';
import { State } from '../state';

import app from './app';
import user from './user';

const rootReducer = combineReducers<State>({
  app,
  user,
});

export default rootReducer;
