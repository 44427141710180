import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './utils/init';
import { initSentry } from './utils/sentry';
import 'bootstrap/dist/css/bootstrap.min.css';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
