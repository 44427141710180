import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Layout } from '../../components';

const CancelAccount = () => {
  const location: any = useLocation();
  const [cancelDate, setCancelDate] = useState('');

  const cancelled = location.state?.cancelled;

  useEffect(() => {
    const now = new Date();
    setCancelDate(
      `${now.getFullYear()}年${
        now.getMonth() + 1
      }月${now.getDate()}日 ${now.getHours()}:${now.getMinutes()}`,
    );
  }, []);

  return (
    <>
      {cancelled ? (
        <Layout withHeader>
          <div className="home-page sm-content">
            <h4 className="page-title">退会手続きの完了</h4>

            <p className="allow-endline">
              {`${cancelDate}に退会しました。\nC-FACEをご利用いただきありがとうございました。またのご利用を心からお待ちしております。`}
            </p>
            <a href="https://www.c-face.jp/" className="cancel-account-link">
              商品ページへ
            </a>
          </div>
        </Layout>
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )}
    </>
  );
};

export default CancelAccount;
