import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';

import { updateAppEnvironment } from '../../../store/actions/app';
import { getEnvironment, getIsSubmitting } from '../../../store/selectors/app';

const BetaVersion = () => {
  const dispatch = useDispatch();
  const appEnv = useSelector(getEnvironment);
  const isSubmitting = useSelector(getIsSubmitting);
  const [disabled, setDisabled] = useState(appEnv.disableBetaVersion);
  const [confirm, setConfirm] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setDisabled(appEnv.disableBetaVersion);
  }, [appEnv.disableBetaVersion]);

  const validate = () => confirm === 'Disable Beta Version';

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      setIsSubmitted(false);
      setConfirm('');
      dispatch(
        updateAppEnvironment({
          disableBetaVersion: disabled,
        }),
      );
    }
  };

  return (
    <>
      <h4 className="page-title">Control Beta Version</h4>
      <form className="admin-form" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <label className="form-check-label mr-4">
              Disable Beta Version
            </label>
            <Switch
              onChange={(checked) => setDisabled(checked)}
              checked={disabled}
            />
          </div>
          <small className="form-text text-muted">
            The current disableBetaVersion is{' '}
            {disabled ? 'true(cannot use)' : 'false(can use)'}.
          </small>
        </div>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type 'Disable Beta Version' for confirmation"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          />
          {isSubmitted && !validate() && (
            <p className="invalid-text">Confirmation value is invalid.</p>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={isSubmitting}>
          {isSubmitting ? '保存しています ...' : '保存'}
        </button>
      </form>
    </>
  );
};

export default BetaVersion;
