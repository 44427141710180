import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { isValidPassword } from '../../../utils/validation';

import { updatePassword } from '../../../store/actions/user';

interface IError {
  oldPassword?: string;
  newPassword?: string;
  confirm?: string;
}

const Password = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirm: '',
  });
  const [errors, setErrors] = useState<IError>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    const e: IError = {};
    if (!inputValues.oldPassword) {
      e.oldPassword = 'パスワードが正しくありません';
    }
    if (!isValidPassword(inputValues.newPassword)) {
      e.newPassword = '6文字以上、40文字以下で入力してください';
    }
    if (inputValues.newPassword !== inputValues.confirm) {
      e.confirm = 'パスワードが一致しません';
    }

    setErrors(e);
    return Object.values(e).length === 0;
  }, [inputValues]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, inputValues]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(
        updatePassword(inputValues, () => {
          setIsSubmitted(false);
          setInputValues({
            oldPassword: '',
            newPassword: '',
            confirm: '',
          });
        }),
      );
    }
  };

  const onBack = () => {
    history.push('/');
  };

  return (
    <>
      <h4 className="page-title">パスワードの変更</h4>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          {errors.oldPassword && (
            <p className="invalid-text">{errors.oldPassword}</p>
          )}
          <input
            type="password"
            className="form-control"
            name="oldPassword"
            placeholder="現在のパスワード"
            value={inputValues.oldPassword}
            onChange={onInputChange}
          />
          <div className="forgot-password">
            <Link to="/forgot-password" className="link-secondary">
              パスワードを忘れた場合
            </Link>
          </div>
        </div>
        <div className="form-group">
          {errors.newPassword && (
            <p className="invalid-text">{errors.newPassword}</p>
          )}
          <input
            type="password"
            className="form-control"
            name="newPassword"
            placeholder="新しいパスワード（6文字以上、40文字以下）"
            value={inputValues.newPassword}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          {errors.confirm && <p className="invalid-text">{errors.confirm}</p>}
          <input
            type="password"
            className="form-control"
            name="confirm"
            placeholder="新しいパスワードの確認"
            value={inputValues.confirm}
            onChange={onInputChange}
          />
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          保存
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={onBack}>
          キャンセル
        </button>
      </form>
    </>
  );
};

export default Password;
