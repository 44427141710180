import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUserInfo } from '../../../store/selectors/user';
import { updateUser } from '../../../store/actions/user';

const Name = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserInfo);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = useCallback(() => {
    if (!name) {
      setError('使用不可能な文字が含まれています');
      return false;
    }

    setError('');
    return true;
  }, [name]);

  useEffect(() => {
    if (isSubmitted) {
      validate();
    }
  }, [validate, isSubmitted, name]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (validate()) {
      dispatch(
        updateUser({ name }, () => {
          toast.success('情報が更新されました。');
          setIsSubmitted(false);
          setName('');
        }),
      );
    }
  };

  const onBack = () => {
    history.push('/');
  };

  return (
    <>
      <h4 className="page-title">氏名の変更</h4>

      <form onSubmit={onSubmit}>
        <label>現在の氏名：{user?.name}</label>

        <div className="form-group">
          {error && <p className="invalid-text">{error}</p>}
          <input
            type="text"
            className="form-control"
            placeholder="氏名"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          保存
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={onBack}>
          キャンセル
        </button>
      </form>
    </>
  );
};

export default Name;
