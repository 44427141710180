import { actionTypes } from '../actions/user';

export type Features =
  | 'speechToText'
  | 'voiceMemo'
  | 'englishTranslate'
  | 'allLanguageTranslate';
// | 'meetingMemo'

export interface IUser {
  id: number;
  name: string;
  displayName: string;
  email: string;
  confirmed: boolean;
  planId: Features;
  incomplete: boolean;
  nextPlanId?: string | null;
  payjpPlanId: string;
  payjpBenefit: string;
  benefit?: string | null;
  expire?: string | null;
  isAdmin: boolean;
  clipDevice?: string;
  clipExpire?: string;
}

export const userInitialState: {
  user: IUser | null;
  registered?: boolean;
} = {
  user: null,
  // to check if user has registered device or not
  registered: undefined,
};

const userReducer = (state = userInitialState, action: any) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        user: action.payload,
      };
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        user: {}, // empty user to trigger state change !important!
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
        };
      }
      return state;
    case actionTypes.UPDATE_REGISTER_STATE_SUCCESS:
      return {
        ...state,
        registered: action.payload.registered,
      };
    case actionTypes.USER_LOGOUT:
      return {
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
