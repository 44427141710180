import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Layout } from '../../components';
import { getEnvironment } from '../../store/selectors/app';

import './styles.scss';
import CfaceBtn from './dlc-cface.png';
import ClipBtn from './dlc-clip.png';
import { getUserInfo } from '../../store/selectors/user';

const CfaceDownload = () => {
  const appEnv = useSelector(getEnvironment);

  return (
    <div className="col-sm">
      <div className="content">
        <h4 className="wide">アプリ</h4>
        <div className="med">
          <div className="app-links">
            <span>C-FACE</span>
            <div className="links mt-2">
              <a href={appEnv.iosAppStoreLink} target="_blank" rel="noreferrer">
                <img src="/assets/images/app-store-badge.png" alt="" />
              </a>
              <a
                href={appEnv.androidPlaystoreLink}
                target="_blank"
                rel="noreferrer">
                <img src="/assets/images/google-play-badge.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <h4 className="wide">
          取扱説明書<small className="ml-3">Ver.2,02</small>
        </h4>
        <div>
          <a
            href="https://47f0f3dc-c051-46f4-9399-7d95d6d4b3e8.usrfiles.com/ugd/47f0f3_cfb8915900a74a7bb22d88b1afe01b81.pdf"
            target="_blank"
            rel="noreferrer">
            <button className="btn btn-primary btn-block mt-4">
              ダウンロード
            </button>
          </a>
        </div>
        <h4 className="wide">FAQ</h4>
        <div className="med">
          <div>Q.製品番号の登録の仕方がわかりません。</div>
          <div>A.製品番号は取扱説明書の最終ページに記載されています。</div>
        </div>
        <div>
          <a
            href="https://forms.gle/YBjwd9Ls2rg3yepKA"
            target="_blank"
            rel="noreferrer">
            <button className="btn btn-primary btn-block mt-4">
              製品についてのお問い合わせはこちら
            </button>
          </a>
        </div>
        <h4 className="wide">
          動作環境<small className="ml-3">2023年1月現在</small>
        </h4>
        <div className="med">
          <div>Android 11,12,13</div>
          <div>iOS 14,15,16</div>

          <div className="mt-3">
            ※お使いの端末の、 OS バージョン、メーカー、機種によっては、
            固有の制約事項がある場合があります。
          </div>
        </div>
      </div>
    </div>
  );
};

const ClipDownload = () => {
  const appEnv = useSelector(getEnvironment);

  const pcLink = useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return 'https://gijiroku-system-app.s3.ap-northeast-1.amazonaws.com/windows/%E3%83%88%E3%82%99%E3%83%BC%E3%83%8A%E3%83%83%E3%83%84%E8%AD%B0%E4%BA%8B%E9%8C%B2%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%20Setup%201.1.0.exe';
    } else {
      return 'https://gijiroku-system-app.s3.ap-northeast-1.amazonaws.com/windows/%E3%83%88%E3%82%99%E3%83%BC%E3%83%8A%E3%83%83%E3%83%84%E8%AD%B0%E4%BA%8B%E9%8C%B2%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%20Setup%201.1.0-development.11.exe';
    }
  }, []);

  const macLink = useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return 'https://gijiroku-system-app.s3.ap-northeast-1.amazonaws.com/macos/%E3%83%88%E3%82%99%E3%83%BC%E3%83%8A%E3%83%83%E3%83%84%E8%AD%B0%E4%BA%8B%E9%8C%B2%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0-1.1.0.dmg';
    } else {
      return 'https://gijiroku-system-app.s3.ap-northeast-1.amazonaws.com/macos/%E3%83%88%E3%82%99%E3%83%BC%E3%83%8A%E3%83%83%E3%83%84%E8%AD%B0%E4%BA%8B%E9%8C%B2%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0-1.1.0-development.11.dmg';
    }
  }, []);

  return (
    <div className="col-sm">
      <div className="content">
        <h4 className="wide">アプリ</h4>
        <div className="med">
          <div className="app-links">
            <span>donut インカム</span>
            <div className="links mt-2">
              <a
                href="https://apps.apple.com/jp/app/income/id1621072603"
                target="_blank"
                rel="noreferrer">
                <img src="/assets/images/app-store-badge.png" alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=jp.co.donutrobotics.clipear"
                target="_blank"
                rel="noreferrer">
                <img src="/assets/images/google-play-badge.png" alt="" />
              </a>
            </div>
          </div>

          <div className="app-links mt-2">
            <span>donut 翻訳</span>
            <div className="links mt-2">
              <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
                <img src="/assets/images/app-store-badge.png" alt="" />
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.donutrobotics.translator"
                target="_blank"
                rel="noreferrer">
                <img src="/assets/images/google-play-badge.png" alt="" />
              </a>
            </div>
          </div>

          <div className="app-links mt-2">
            <span>donut 対面翻訳</span>
            <div className="links mt-2">
              <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
                <img src="/assets/images/app-store-badge.png" alt="" />
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.clipear_translator"
                target="_blank"
                rel="noreferrer">
                <img src="/assets/images/google-play-badge.png" alt="" />
              </a>
            </div>
          </div>

          <div className="app-links mt-2">
            <span>
              donut 議事録
              <small className="ml-2">Ver.1.1 （2023年02月02日更新）</small>
            </span>
            <div className="links flex-column mt-2 px-sm-3 px-md-5">
              <div>Windowsをお使いの方はこちら</div>
              <a href={pcLink} target="_blank" rel="noreferrer">
                <button className="btn btn-primary btn-block mb-3">
                  利用規約に同意してダウンロード
                </button>
              </a>
              <div>Macをお使いの方はこちら</div>
              <a href={macLink} target="_blank" rel="noreferrer">
                <button className="btn btn-primary btn-block mb-3">
                  利用規約に同意してダウンロード
                </button>
              </a>
              {appEnv.privacyUrl ? (
                <a
                  href={appEnv.privacyUrl}
                  className="text-dark text-center"
                  target="_blank"
                  rel="noreferrer">
                  利用規約を確認する
                </a>
              ) : (
                <Link to="/privacy-policy" className="text-dark text-center">
                  利用規約を確認する
                </Link>
              )}
            </div>
          </div>
        </div>
        <h4 className="wide">
          取扱説明書<small className="ml-3">Ver.1,0</small>
        </h4>
        <div>
          <a
            href="https://drive.google.com/file/d/14a_OsWZxYitBLQ0aVr3ZRjeUjW603ARP/view?usp=share_link"
            target="_blank"
            rel="noreferrer">
            <button className="btn btn-primary btn-block mt-4">
              ダウンロード
            </button>
          </a>
        </div>
        <div className="wide mt-4">
          <div className="video-embed">
            <div className="text-muted">
              {/* <small>動画埋め込み</small> */}
              <iframe
                title="clip EAR チュートリアル"
                id="ytplayer"
                style={{ width: '100%', height: '100%', border: 0 }}
                src="https://www.youtube-nocookie.com/embed/XpjqGiOYm5c?controls=1&loop=1&iv_load_policy=3"
                allowFullScreen
              />
            </div>
          </div>
        </div>

        <div className="wide mt-4">
          <div className="video-embed">
            <div className="text-muted">
              {/* <small>動画埋め込み</small> */}
              <iframe
                title="対面翻訳アプリ | 操作方法"
                id="ytplayer"
                style={{ width: '100%', height: '100%', border: 0 }}
                src="https://www.youtube-nocookie.com/embed/9jEgzcANEN8?controls=1&loop=1&iv_load_policy=3"
                allowFullScreen
              />
            </div>
          </div>
        </div>

        <h4 className="wide">FAQ</h4>
        <div className="med">
          Q.製品番号の登録の仕方がわかりません。
          <br />
          A.製品番号は取扱説明書の最終ページに記載されています。
          <br />
          <br />
          Q.アカウント発行したいけどメールが届きません。
          <br />
          A.迷惑メールフォルダをご確認ください。また、キャリアによりはじかれている可能性があるため、PCで確実に受信できるメールアドレスをご用意下さい。
          <br />
          <br />
          Q.製品番号がどこにあるかわかりません。
          <br />
          A.clip EARに同梱の取扱説明書内最終ページに記載しています。
          <br />
          <br />
          Q.製品番号を紛失してしまったのですが、どうしたらよいですか？
          <br />
          A.購入したサイト、氏名、日時、メールアドレスを記載の上、こちらまでメールをお送りください。確認後、お返事させていただきます。（cs@donutrobotics.com）
          <br />
          <br />
          Q.clip EARを購入しなくてもソフトは使えますか？
          <br />
          A.clip
          EAR購入者の方から、招待コードを共有することでdonut議事録システムを期間限定で使用できます。※インカムアプリはclip
          EARご購入いただいた方限定で利用可能です
          <br />
          <br />
          Q.clip EARとPCが接続されません。どうしたらよいですか？
          <br />
          A.clip
          EARの電源がONになっていること、PCのBluetoothがONになっていることを確認してください。
          <br />
          <br />
          Q.字幕が出てきません。どうしたらいいですか？
          <br />
          A.マイクの設定をご確認ください。PC、donut議事録ソフトのマイクはともに「clip
          EAR」を選択してください。それでも表示されない場合はソフトウェアを一度終了し、再度立ち上げてください。
          <br />
          <br />
        </div>
        <div>
          <a
            href="https://forms.gle/YBjwd9Ls2rg3yepKA"
            target="_blank"
            rel="noreferrer">
            <button className="btn btn-primary btn-block mt-4">
              製品についてのお問い合わせはこちら
            </button>
          </a>
        </div>
        <h4 className="wide">
          動作環境<small className="ml-3">2023年1月現在</small>
        </h4>
        <div className="med">
          <div>
            <b>donut インカム</b>
          </div>

          <div className="mt-3">Android 12,13</div>
          <div>iOS 15,16</div>

          <div className="mt-3">
            ※お使いの端末の、 OS バージョン、メーカー、機種によっては、
            固有の制約事項がある場合があります。
          </div>

          <div className="mt-3">
            <b>donut 議事録</b>
          </div>

          <div className="mt-3">対応OS</div>
          <div>Windows 11 / Windows10 / Windows 8.1(64bit版)</div>
          <div>macOS Ventura(v13.2) / Monterey (v12.0)/ Big Sur (v11.0)</div>

          <div className="mt-3">CPU・メモリ</div>
          <div>各OSごとの推奨環境</div>
        </div>
      </div>
    </div>
  );
};

const Download = () => {
  // const { state }: { state: any } = useLocation();
  const { device }: any = useParams();
  const user = useSelector(getUserInfo);

  const isClipPage = useMemo(() => {
    return device === 'clip';
  }, [device]);

  return (
    <Layout
      withHeader
      headerCenter={!user}
      userName={user ? user.displayName : undefined}>
      <div className="container-fluid download-page">
        <div className="row">
          <div className="col-sm-auto mb-3 d-flex justify-content-center">
            <div className="dlc-page-btn">
              <img src={isClipPage ? ClipBtn : CfaceBtn} alt="Device" />
            </div>
          </div>
          {isClipPage ? <ClipDownload /> : <CfaceDownload />}
        </div>
      </div>
    </Layout>
  );
};

export default Download;
