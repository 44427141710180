import { Link, useParams } from 'react-router-dom';

export default function NavBar({
  root,
  paths,
}: {
  root: string;
  paths: {
    segment?: string;
    text: string;
  }[];
}) {
  let { segment } = useParams<{ segment?: string }>();
  if (!segment) segment = '';

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light mb-2">
      <div style={{ fontWeight: 'bold' }}>
        <ul className="navbar-nav">
          {paths.map((path) => (
            <li
              key={path.segment}
              className={`nav-item${
                segment === path.segment ? ' active' : ''
              }`}>
              <Link className="nav-link" to={`${root}/${path.segment}`}>
                {path.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
