import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { Layout, ScrollToTopRoute } from './components';
import {
  Admin,
  Cancelled,
  Download,
  EmailConfirm,
  Forgot,
  Home,
  Notice,
  Login,
  Privacy,
  Register,
  Reset,
  Settings,
  Terms,
} from './pages';

import configureStore from './store';
import titles from './utils/titles';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import useUserInfo from './useUserInfo';

const store = configureStore();

const FullPageLoading = () => {
  return <div className="full-page-loading">処理中。。。</div>;
};

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const Component = component!;
  const { user, token } = useUserInfo();
  document.title = titles.mypage;

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          user ? (
            <Layout withHeader>
              <Component {...props} />
            </Layout>
          ) : (
            <FullPageLoading />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AuthRoute = ({ component, ...rest }: RouteProps) => {
  const Component = component!;
  const { user, token } = useUserInfo();
  document.title = titles.register;

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          user ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <FullPageLoading />
          )
        ) : (
          <Layout withHeader={rest.path !== '/login'}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

const AdminRoutes = () => {
  const { user, token } = useUserInfo();
  document.title = titles.mypage;

  return (
    <>
      {token ? (
        user ? (
          user.isAdmin && <Route path="/admin/appenv" component={Admin} />
        ) : (
          <FullPageLoading />
        )
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )}
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTopRoute>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/settings" component={Settings} />

            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/notice" component={Notice} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/forgot-password" component={Forgot} />
            <Route exact path="/reset-password" component={Reset} />
            <Route exact path="/email-confirmation" component={EmailConfirm} />
            <Route exact path="/cancelled" component={Cancelled} />
            <Route exact path="/download/:device?" component={Download} />

            <AuthRoute exact path="/login" component={Login} />
            <AuthRoute exact path="/signup" component={Register} />

            <AdminRoutes />
          </Switch>
        </ScrollToTopRoute>
        <ToastContainer />
      </Router>
    </Provider>
  );
}

export default App;
