import DeviceDownloads from '../../../components/DeviceDownloads';

const Download = () => {
  return (
    <>
      <h4 className="page-title">各種ダウンロード</h4>
      <DeviceDownloads />
    </>
  );
};

export default Download;
