import * as Sentry from '@sentry/react';
import { IUser } from '../store/reducers/user';
import { getCurrentEnvironment } from './environments';

const commitHash =
  process.env.REACT_APP_GIT_COMMIT_HASH || process.env.COMMIT_REF || 'not-set';
const currentBranch =
  process.env.REACT_APP_GIT_CURRENT_BRANCH || process.env.BRANCH || 'not-set';

const environment = getCurrentEnvironment();

const allowedEnvs: typeof environment[] = ['netlify', 'local', 'staging'];

export const initSentry = () => {
  if (allowedEnvs.includes(environment)) {
    Sentry.init({ dsn: process.env.SENTRY_DSN, environment });
    Sentry.setTags({
      commit: commitHash,
      branch: currentBranch,
    });
  }
};

export const setSentryTag = (user: IUser) => {
  Sentry.setTag('display.name', user.displayName);
  Sentry.setTag('name', user.name);
  Sentry.setTag('email', user.email);
};
